.update-gallery-photo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0.4rem;
}

.gallery-photo-update {
    max-width: 100%;
    height: 15vh;
    max-height: 150px;
}