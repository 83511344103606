* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
#root {
    min-height: 100vh;
    background-color: #afdde9;
    display:flex;
    flex-direction:column;
}

.container {
    flex: 1;
}

  ::-webkit-scrollbar {
    width: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #afdde9;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #162d50;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #2f5ba7;
  }