.footer-container {
    background-image: url("../../public/icons/footerv2.svg");
    background-repeat: repeat-x;
    background-size: contain;
    height: 60px;
  }
  
  .copy h5 {
      color: rgb(22, 45, 80);
      padding: 40px 0 0 20px;
  }
  
  .copy a {
      text-decoration: none;
      color: rgb(22, 45, 80);
  }
  
  .copy a:hover {
      color: #2f5ba7;
  }
  
  @media  (max-width: 583px) {
      .footer-container {
          background-size: cover;
      }
  }