.custom-file-input {
    display: none;
}

.button-disabled {
    background-color: grey;
}
.button-disabled:hover {
    cursor: not-allowed;
    background-color: grey;
}