.hero-container {
    background: url('https://galeria.bankier.pl/p/0/0/0767395c071c9f-948-568-0-67-1000-600.jpg') center center/cover no-repeat, #afdde9;
    height: 90vh;
    min-height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);

}

.zapisy-text {
    background-size: cover;
    padding: 1%;
    border-radius: 25px;
    background-color: rgba(77, 199, 215, 0.2);
}

a {
    color: #162d50;
    text-decoration: none;
}

a:hover {
    color: #2f5ba7;
}

button {
    background-color: #162d50;
    color: white;
    font-size: 15px;
    width: 50%;
    height: 2.5em;
    ;
    border-radius: 25px;
    border: none;
}

button:hover {
    background-color: #2f5ba7;
    border: none;
    cursor: pointer;
}

.text {
    padding: 1%;
    border-radius: 25px;
    background-color: rgba(22, 45, 80, 0.4);
    background-size: cover;
    margin-top: 90px;
}

.text>h1 {
    text-align: center;
    color: #fff;
    font-size: 100px;
    text-shadow: 2px 2px #121a31;

}

.text>p {
    text-align: center;
    color: #fff;
    font-size: 45px;
    margin-top: 8px;
    text-shadow: 2px 2px #121a31;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.items-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.item {
    vertical-align: top;
    text-align: center;
    margin: 1%;
    padding: 1%;
    background-color: white;
    display: inline-block;
    border-radius: 25px;
    width: 20%;
    color: #162d50;
}

.item img {
    width: 20%;
}

@media screen and (max-width: 1175px) {


    .hero-container {
        min-height: 660px;
    }

    .items-container h1 {
        font-size: 26px;
    }

    .items-container h4 {
        font-size: 14px;
    }

    .items-container h3 {
        font-size: 18px;
    }
}

@media screen and (max-width: 1080px) {

    .text>h1 {
        font-size: 70px;
    }

    .text {
        margin-top: 0px;
    }
}

@media screen and (max-width: 1040px) {
    .hero-container {
        min-height: 550px;
    }

    .item {
        width: 29%;
    }
}


@media screen and (max-width: 768px) {
    .hero-container {
        height: 75vh;
        min-height: 515px;
    }

    /* .text {
        margin-top: -100px;
    } */

    .text>h1 {
        font-size: 50px;
    }

    .text>p {
        font-size: 30px;
    }
}

@media screen and (max-width: 700px) {
    .hero-container {
        min-height: 450px;
    }

    .item {
        width: 50%;
        height: fit-content;
    }

    .item img {
        width: 14%;
    }

    .items-container h1 {
        font-size: 22px;
    }

    .items-container h4 {
        font-size: 12px;
    }

    .items-container h3 {
        font-size: 14px;
    }
}

@media screen and (max-width: 510px) {
    .hero-container {
        height: 85vh;
        min-height: 700px;
    }

    .text>h1 {
        font-size: 35px;
    }

    .text>p {
        font-size: 25px;
    }

    .items-container {
        flex-direction: column;
        height: 40%;
        justify-content: unset;
    }
}

@media screen and (max-width: 368px) {
    .hero-container {
        height: 80vh;
        min-height: 570px;
    }

    .text>h1 {
        font-size: 25px;
    }

    .text>p {
        font-size: 20px;
    }

    .items-container h1 {
        font-size: 18px;
    }

    .items-container h4 {
        font-size: 10px;
    }

    .items-container h3 {
        font-size: 12px;
    }
}