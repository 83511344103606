.about-container{
    background-color: #afdde9;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    }
    .main{
        display: inline-block;
        margin:1%;
        border-radius: 25px;
        padding: 3%;
        background-color: white;
        color: #162d50;
        width:60%;
    }
    .main h2{
        text-align: center;
    }
    .main-mini{
        border-radius: 25px;
        vertical-align: top;
        font-size: 14px;
        width: 18%;
        margin:1%;
        padding: 1%;
    }
    .main-mini .fa-solid{
        font-size: 40px;
        margin-bottom: 5%;
    }
    .main img{
        width: 45%;
        margin:1%;
        padding: 3%;
    }
    .main-img{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    }
    ul{
        margin:1%;
        padding: 1%;
    }
    
    .sun-img{
        display: inline-block;
    }
    .sun-img{
        width: 10%;
    }
    .main-podzial{
        background-color: transparent;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .kafelek{
        align-items: center;
    justify-content: center;
    display: flex;
    height: 400px;
    }
    .kafelek.tlo{
        color: #162d50;
        background-image: url('../../public/images/cloud.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align:bottom;
        align-items: center;
        justify-content: center;
        display: flex;
        text-align: center;
        border-radius: 25px;
        padding:1%;
        
    }
    .kafelek.tlo>h2{
        padding: 2%;
        margin:1%;  
        font-size: 22px;
        text-align: center;
        width: 60%;
        text-shadow: 2px 2px #afdde9;
    }
    .kafelek.tlo.stack{
    margin-left: -5%;
    }
    
    @media screen and (max-width: 1440px) {
        .kafelek.tlo>h2{
            font-size: 16px;
        }
    }
    
    @media screen and (max-width: 820px) {
        .main {
            width: 80%;
        }
    
        .main h1 {
            font-size: 26px;
        }
    
        .main h2 {
            font-size: 20px;
        }
    
        .main h3 {
            font-size: 16px;
        }
        .kafelek.tlo > h2{
            font-size: 13px;
        }
        .main-mini{
            width: 160%;
            padding: 2%;
        }
    }
    
    @media screen and (max-width: 600px) {
        .main-podzial img {
            align-self: center;
        }
    
        .main-podzial {
            /* width: 100%; */
            display: flex;
            flex-direction: column;
        }
    
        .main h1 {
            font-size: 24px;
        }
    
        .main h2 {
            font-size: 18px;
        }
    
        .main h3 {
            font-size: 14px;
        }
    
        .main-img {
            flex-direction: column;
        }
    
        .main-mini {
            margin: 2%; width: 80%; padding: 2%;
        }
    
    
        .kafelek{
            height: 200px;
        }
    
        .kafelek.tlo>h2{
            font-size: 16px;
        }
        .kafelek.tlo {
            height: 40vh;
            margin-top: -10%;
            margin-bottom: -10%;
        }
    }
    
    
    
    @media screen and (max-width: 362px) {
        .opac.sun {
            height: 15vh;
        }
        .main h1 {
            font-size: 16px;
        }
    
        .main h2 {
            font-size: 10px;
        }
    
        .main h3 {
            font-size: 10px;
        }
    
        .main-podzial.over {
            padding-left: 4%;
        }
        .main-mini .fa-solid{
            font-size: 25px;
        }
        .main-mini {
            padding: 2%;
           font-size: 10px;
           margin:1%;
        }
    }