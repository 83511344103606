.gallery-photos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.gallery-photo {
    max-width: 100%;
    height: 25vh;
    max-height: 300px;
    margin: 0.5rem;
    -webkit-box-shadow: 0px 0px 26px -6px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 26px -6px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 26px -6px rgba(66, 68, 90, 1);
}