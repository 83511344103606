.gallery-photos-delete {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}

.gallery-photo-delete {
    max-width: 100%;
    height: 15vh;
    max-height: 150px;
}

.to-delete {
    opacity: 0.4;
}

.gallery-delete-button {
    position: absolute;
    z-index: 1;
}

.delete-gallery-photo-container {    
    display: flex;
    background-color: rgb(150, 50, 50);
    margin: 0.4rem;
}