.strefa-container {
  background-color: #afdde9;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  min-height: calc(100vh - 160px);
}

.navbar-logo {
  width: 10vh;
  cursor: auto;
}

.top-text {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.password-input {
  align-items: center;
}

.strefa-rodzic {
  display: inline-block;
  margin: 1%;
  border-radius: 25px;
  padding: 10%;
  background-color: white;
  color: #162d50;
  width: 60%;
}

.input-field {
  width: 78%;
  padding: 15px;
  border-radius: 25px;
  margin: 0 0 15px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

.input-eye {
  display: inline;
  position: relative;
}

.visibility-button {
  height: 1.5rem;
  position: absolute;
  right: 4%;
  top: calc(50% - 0.7em)
}

.button-strefa {
  margin: 1%;
  width: 20%;
}

.error-message {
  color: rgb(255, 112, 112);
  text-align: center;
  margin-right: 20%;
}

.parent-form {
  padding-top: 3%;
}

@media screen and (max-width: 880px) {
  .strefa-text>h1 {
    font-size: 24px;
  }

  .strefa-text>h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 760px) {
  .error-message {
    margin: 0;
  }

  .strefa-rodzic {
    padding: 5%;
  }

  .top-text {
    flex-direction: column;
    align-items: center;
  }

  .strefa-text {
    text-align: center;
  }

  .navbar-logo {
    margin: 0;
  }

  .password-input {
    text-align: center;
  }

  .input-field {
    width: 100%;
  }

  .button-strefa {
    width: 50%;
  }
}

@media screen and (max-width: 580px) {
  .strefa-rodzic {
    width: 80%;
  }
}

@media screen and (max-width: 362px) {
  .strefa-rodzic {
    width: 90%;
  }
}